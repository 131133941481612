import { useEffect, useState } from 'react';
import { KeyedMutator } from 'swr';
import { IHardwareDevice, IHardwareRequest } from '../../../routes/systems/models/IHardware';
import { getUrlWithQueryParams, httpGetJson, httpPost } from '../../backend/http/http';
import { Urls } from '../../backend/urls';
import { wsGet } from '../../backend/websockets/websockets';
import { useSiteInfo } from './useSiteInfo';
import { useEntryUsersTotals } from './useEntryUsers';
import { ISiteInfo } from '../../../routes/systems/models/ISiteInfo';
import { StatusCodesEnum } from '../constants/StatusCodesEnum';

export const DEFAULT_HARDWARE_GROUP = '--//';

export interface IConnectDeviceModel {
  panelSerial: string;
  engineerCode: string;
  siteGuid: string;
  companyId: string;
}

export type HardwareGroupsType = Record<string, IHardwareDevice[]>;

interface IBindResponse {
  code: number;
  result: string;
  siteId: string;
  panelSerial: string;
}

const UI_HARDWARE_TIMEOUT = 60000;

export async function bindHardware(data: IConnectDeviceModel): Promise<'success' | 'error'> {
  if (!data.companyId) {
    return 'error';
  }

  try {
    const response: IBindResponse = await wsGet(async () => {
      await httpPost(Urls.ConfigBind, JSON.stringify(data));
    }, Urls.ConfigNegotiate, UI_HARDWARE_TIMEOUT);

    return response.code >= StatusCodesEnum.SuccessOK ? 'success' : 'error';
  } catch {
    return 'error';
  }
}

export function useHardwareSummary(siteId?: string): {
  hardwareGroups?: HardwareGroupsType,
  isSiteBound?: boolean,
  mutate: () => Promise<void>,
  reset: () => void,
  setEmptyHardware: () => void
  isOffline: boolean,
  mobileUsersCount?: number,
  isLoading: boolean,
  refreshSiteInfo?: KeyedMutator<ISiteInfo>
} {
  const [hardwareGroups, setHardwareGroups] = useState<HardwareGroupsType | undefined>(undefined);
  const [isOffline, setIsOffline] = useState(false);
  const { siteInfo, mutate: refreshSiteInfo } = useSiteInfo(siteId);
  const { usersTotals } = useEntryUsersTotals(siteInfo?.id);
  const setHardwareGroupsFromItems = (hardwareSummary: IHardwareRequest | undefined) => {
    if (hardwareSummary?.hardwareSummary && hardwareSummary?.hardwareSummary.length > 0) {
      const groups = hardwareSummary.hardwareSummary.reduce((_hardwareGroups, hardwareData) => {
        const key = hardwareData.group || DEFAULT_HARDWARE_GROUP;
        const hardwareItems = _hardwareGroups[key] || [];
        hardwareItems.push(hardwareData);

        return ({
          ..._hardwareGroups,
          [key]: hardwareItems,
        });
      }, {} as HardwareGroupsType);
      setHardwareGroups(groups);
    } else {
      setHardwareGroups({});
    }
  };
  useEffect(() => {
    if (siteInfo?.isSiteBound) {
      setHardwareGroups(undefined);
      wsGet<IHardwareRequest>(async () => {
        const devices = await httpGetJson<IHardwareDevice[]>(getUrlWithQueryParams(Urls.HardwareSummary(siteId)!,
          {
            companyId: siteInfo?.companyId,
            useStoredData: true,
          }));
        setHardwareGroupsFromItems({ hardwareSummary: devices });
      }, Urls.HardwareNegotiate(siteId), UI_HARDWARE_TIMEOUT)
        .then(setHardwareGroupsFromItems)
        .then(() => setIsOffline(false))
        .catch((e) => {
          // to be explicit for dev, otherwise error is swallowed
          // eslint-disable-next-line no-console
          console.error(e);
          setHardwareGroupsFromItems({
            hardwareSummary: [],
          });
          setIsOffline(true);
        });
    } else {
      setHardwareGroupsFromItems({
        hardwareSummary: [],
      });
      setIsOffline(false);
    }
  }, [siteInfo?.isSiteBound]);

  return {
    hardwareGroups,
    isSiteBound: siteInfo?.isSiteBound,
    mobileUsersCount: usersTotals?.totalUsers,
    reset: () => setHardwareGroups(undefined),
    mutate: async () => {
      await wsGet<IHardwareRequest>(
        getUrlWithQueryParams(Urls.HardwareSummary(siteId)!,
          { companyId: siteInfo?.companyId }),
        Urls.HardwareNegotiate(siteId),
        UI_HARDWARE_TIMEOUT,
      )
        .then(setHardwareGroupsFromItems)
        .then(() => setIsOffline(false))
        .catch((e) => {
          // to be explicit for dev, otherwise error is swallowed
          // eslint-disable-next-line no-console
          console.error(e);
          setHardwareGroupsFromItems({
            hardwareSummary: [],
          });
          setIsOffline(true);
        });
    },
    setEmptyHardware: () => {
      setHardwareGroupsFromItems({
        hardwareSummary: [],
      });
    },
    isOffline,
    isLoading: hardwareGroups == null || siteInfo == null || usersTotals == null,
    refreshSiteInfo,
  };
}
